function init_mobile_menu() {
    var $toggle = $('.mobile-bar__toggle');
    var $screen = $('.screen');
    var $sidebar = $('.screen__sidebar');

    if(!$toggle || !$screen) {
        return false;
    }

    $toggle.on('click', function() {
        if(!$screen.hasClass('mobile-menu-open')) {
            $sidebar.scrollTop(0)
        }
        $screen.toggleClass('mobile-menu-open');
    });
}

$(document).ready(function () {
    init_mobile_menu();
});