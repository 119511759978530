function init_forms() {
    var $forms = $('form.cpx-form--preventdouble');

    if(!$forms) {
        return false;
    }

    $forms.each(function() {
        var $form = $(this);
        $form.on('submit', function() {
            $form.find('button[type="submit"]').prop('disabled', true);
            $form.addClass('loading');
            $form.append('<div class="loading-screen"><div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>');
        });
    });
}

$(document).ready(function () {
    init_forms();
});