function init_coming_soon_notice() {
    var $close = $('.js-close-coming-soon');
    var $notice = $('.coming-soon-notice');
    var $notice_backdrop = $('.coming-soon-notice__backdrop');

    if(!$close) {
        return false;
    }

    $close.on('click', function() {
        $notice.addClass('fadeout');
        $notice_backdrop.addClass('fadeout');

        setTimeout(function() {
            $notice.remove();
            $notice_backdrop.remove();
        }, 450);
    });
}

$(document).ready(function () {
    init_coming_soon_notice();
});